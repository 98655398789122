import styles from './css/Rejection30.module.css';
import cocaTeam from '../../assets/coca-team-with-drops.png';
import cocaLogo from '../../assets/coca-logo-white.png';
import taqeLogo from '../../assets/taqe-logo-green.png';

const Rejection30 = () => {
  return (
    <div className={styles.rejection_page}>
      <div className={styles.rejection_message}>
        <div className={styles.background} />
        <h1 className={styles.title}>
          Agradecemos pelo interesse, mas sua inscrição não foi realizada.
        </h1>
        <div className={styles.team_coca_image}>
          <img src={cocaTeam} alt="Team Coca" />
        </div>
      </div>
      <div className={styles.message_whatsapp_container}>
        <div className={styles.whatsapp_text_box}>
          <div className={styles.whatsapp_text}>
            <span>
              O Coletivo Online atua com jovens de até 29 anos, portanto não
              desanime: você pode procurar milhares de vagas dentro da{' '}
              <a
                href="https://www.vagas.taqe.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Taqe
              </a>
              .
            </span>
          </div>
          <div className={styles.logosContainer}>
            <div className={styles.cocaLogo}>
              <img src={cocaLogo} alt="Logo do Instituto Coca-Cola Brasil" />
            </div>
            <div className={styles.taqeLogo}>
              <img src={taqeLogo} alt="Logo da Taqe" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejection30;
